// eslint-disable-next-line no-redeclare,no-unused-vars
function buildDropdown(cont, data, submenu, end, sort) {
  var className = 'dropdown-menu';
  if (end) className += ' dropdown-menu-end';
  var ul = $('<ul class="' + className + '"></ul>').appendTo(cont);
  if (submenu) ul.addClass('dropdown-submenu dropdown-submenu-left');
  $.each(data.list, function (i, key) {
    var li = $('<li></li>').appendTo(ul);
    if (key == 'line') return $('<hr class="dropdown-divider">').appendTo(li);

    var point = data[key];
    var label = point.label;
    if (!label) label = key;
    var item = $(
      '<a href="javascript:void(0)" class="dropdown-item">' + label + '</a>'
    ).appendTo(li);

    if (point.list) {
      item.append(' &raquo;');
      buildDropdown(li, point, true);
    } else
      item.on(click, function (evt) {
        menuClick(evt, point, key);
      });
  });
  if (data.list && sort) tinysort(ul.children());
}
